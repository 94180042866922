import React from "react";

// import careers from '../../static/careers/careers-cropped-2.jpg';
// import internship from '../../static/careers/internship.jpg';

import Layout from "../../../components/Layout/Layout";

const Article13012021 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">
              HANNES NIEDERHAUSER, CEO S&T AG, ON TRENDS 2021
            </h1>
            <p className="bold">
              Hannes Niederhauser, CEO of S&T AG, has identified five defining
              trends in the industry for the coming year: Predictive
              Maintenance, High Performance Computing/AI, 5G and 10G-PON,
              Functional Safety and the SDC standard in the medical sector.
            </p>
            <p>
              “Corona has changed the world and given a boost to digitalization
              in many areas. This is particularly evident in the industrial
              environment. Processes around production have also changed. We see
              a trend from off-shoring to Asia back to in-shoring to Europe.
              However, competitive production requires a high degree of
              automation. This requires state-of-the-art technologies. By 2025,
              around 75,000 million machines will be integrated into the
              Internet of Things (IoT) and the Industrial Internet of Things
              (IIoT). The leading market research institutes forecast annual
              growth of 31%. Due to this development, gigantic amounts of data
              are being generated around the globe, which must be evaluated and
              distributed reliably, quickly and securely. Kontron, being
              responsible for the IoT Solutions division within the S&T Group,
              has already set the course for corresponding solutions with the
              development of numerous key technologies.
            </p>
            <p className="bold">
              Practical solutions for the digital transformation
            </p>
            <p>
              In 2021, we will focus, among other things, on further supporting
              small and medium-sized enterprises. We support them in their
              strategic orientation, the consistency of applications and the
              feasibility of solutions in the ecosystem of the respective
              company. To this end, we offer everything for digital
              transformation within the SUSiEtec portfolio, from consulting to
              HW/SW bundles, system integration, SW development, hybrid cloud to
              installation and maintenance. Digitalisation is also becoming
              increasingly prevalent in the service sector. Predictive
              maintenance of components, coupled with automatic and coordinated
              deployment plans, offer new business models. We offer suitable
              tools for this, such as SUSiEtec Workforce, to digitally control
              the deployment of service teams; or the Equipment Cloud, in which
              all hardware and software versions for service purposes are stored
              and can be called up at any time.
            </p>
            <p className="bold">
              High Performance Computing/AI with a new standard
            </p>
            <p>
              The trend topic HPC (High Performance Computing) in the embedded
              edge computing sector will continue to accompany us in 2021. With
              the new COM-HPC® standard, a comprehensive range of solutions can
              be realised in the future which require the highest computing
              power, large storage capacities and high bandwidths. The spectrum
              of applications ranges from edge servers, AI/ML/DL solutions in
              industry, stationary testers and mobile measuring devices to
              mobile edge computing for LTE/5G networks and rugged computers for
              autonomous vehicles such as shuttle buses, taxis or vehicles in
              the agricultural and heavy-duty sector, which will be networked
              with GPS and AI functionality via 5G. For this purpose, we are
              planning the first COM-HPC® server modules with server-class
              processors for the second half of 2021. Virtualization,
              consolidation of applications and AI support are also offered by
              the new industrial Kontron modules, boards and systems that can be
              networked in real-time using TSN (Time Sensitive Networking). We
              see OPC UA over TSN, being an open, cross-industry and
              manufacturer-independent standard, as an essential basis for
              successful digitalization in the factory.
            </p>
            <p className="bold">5G and 10G-PON create new possibilities</p>
            <p>
              The new mobile communications standard 5G enables enormous
              bandwidths, real-time applications and a large number of
              participants with high security. Private networks can also be
              implemented in smart factories, for example to securely network
              and monitor flexible manufacturing cells, robots, AGVs, machines
              and devices with the lowest latency, e.g. in connection with TSN
              and OPC UA. Kontron has a large technology pool in the area of
              "Mission Critical Communication" and can build on the many years
              of experience of its communication teams for the further
              adaptation of 5G. In the area of public safety and emergency
              services, 5G will become the platform for reliable real-time
              communication. SNS Telecom forecasts the residential LTE and 5G
              market to grow at a CAGR of 19% between 2020 and 2023. We expect
              this trend to continue until 2030. The pandemic has significantly
              increased the trend towards home office and remote learning. We
              therefore expect investments in upgrading the broadband access
              network to 10G PON (Passive Optical Network) solutions to increase
              in the coming months. Numerous communications service providers
              have already started upgrading in 2020 and Kontron is supporting
              several projects here. According to Omdia Research, further strong
              growth is expected from 2021 onwards.
            </p>
            <p className="bold">Functional Safety</p>
            <p>
              IoT, IIoT and Industry 4.0 applications are gaining in complexity
              due to the rapid market developments. The role of FuSa (Functional
              Safety) will therefore become all the more decisive in the future
              in order to avoid errors and prevent dangerous system failures.
              This ensures the protection of people, but also prevents damage to
              property. Kontron is planning its own hardware-based "FuSa-ready"
              products for the end of 2021.
            </p>
            <p className="bold">Medical technology is becoming more digital</p>
            <p>
              In medical technology, the SDC (Service-Oriented Device
              Connectivity) protocol will play a central role in the future.
              Kontron plans to support its own products for the medical sector
              with SDC as part of its membership in the OR.NET e.V.. The SDC
              standard ensures the fundamental interoperability of medical
              devices and provides for standardised and secure data exchange. As
              a result, clinical processes can be digitalised, staff can be
              relieved and patient safety can be increased. 2021 will be an
              exciting year from a technological perspective. The pandemic will
              probably stay with us for a while and will continue to drive
              digitalization in many areas. As a global, diversified and
              financially strong and innovative, reliable partner, we are
              ideally positioned to accompany our customers with their digital
              transformation. New applications will change processes and make
              them more secure. Standards simplify communication between devices
              and between man and machine. We will all benefit from this.”
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article13012021;
